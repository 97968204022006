<template>
    <div class="PbFooter">
        <el-text class="mx-1">
            Arkilovesprogramming - 2022 🖥️ <br/>
            Built with Vue3, Element UI, AWS, Bootstrap5...
        </el-text>
    </div>
</template>
<script>
    export default {
        name: "PbFooter"
    }
</script>
<style lang="css" scoped>
    .PbFooter{
        text-align: center;
    }
</style>